<template>
	<div class="">
		<center-title :title="acountMode == 'add' ? '新增审批' : '编辑审批'" :border="false">
		</center-title>
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="162px" class="ruleForm">
			<el-form-item label="功能节点" prop="approvalType" class="mb30">
			  <el-select v-model="ruleForm.approvalType" placeholder="请选择功能节点">
			    <el-option v-for="(item, index) in approvalTypeLists" :key="index" :label="item.value" :value="item.key" >
			    </el-option>
			  </el-select>
			</el-form-item>
			<el-form-item label="初审人" prop="earlyApproval" class="mb30">
			  <el-select v-model="ruleForm.earlyApproval" placeholder="请选择功能节点">
			    <el-option v-for="(item, index) in managerLists" :key="index" :label="item.nickname" :value="item.id" >
			    </el-option>
			  </el-select>
			</el-form-item>
			<el-form-item label="终审人" prop="finalApproval" class="mb30">
			  <el-select v-model="ruleForm.finalApproval" placeholder="请选择功能节点">
			    <el-option v-for="(item, index) in managerLists" :key="index" :label="item.nickname" :value="item.id" >
			    </el-option>
			  </el-select>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" class="white md bg-primary" @click="submitForm('ruleForm')">
					{{ acountMode == 'add' ? '新增' : '保存修改' }}
				</el-button>
				<el-button class="reset bg-center white md" @click="resetForm('ruleForm')" v-if="acountMode != 'edit'">
					重置
				</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>
<script>
	import CenterTitle from "../components/CenterTitle";
	import {
		vxRule
	} from "@/utils/validator";
	export default {
		name: "",
		props: {},
		components: {
			"center-title": CenterTitle,
		},
		data() {
			return {
				acountMode: 'add',
				ruleForm: {
				  approvalType: "",
				  earlyApproval: "",
				  finalApproval: ""
				},
				rules: {
				  approvalType: vxRule(true, null, "change", "请选择功能节点"),
				},
				approvalTypeLists: [],
				managerLists: [],
			};
		},
		computed: {},
		watch: {},
		methods: {
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let api = (this.acountMode == 'add' ? this.$api.addApprovalApi : this.$api
							.editApprovalApi)

						api(this.ruleForm).then((res) => {
							if (res.code == 1) {
								this.$message({
									message: res.msg,
									type: "success",
									onClose: () => {
										this.$router.go(-1);
									},
								});
							}
						});
					} else {
						return false;
					}
				});
			},
			// 重置
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			// 获取管理员列表
			getManagerLists() {
				this.$api.getManagerListsApi().then(res => {
					this.managerLists = res.data
				})
			},
			// 获取审批类型列表
			getApprovalTypeLists() {
				this.$api.getApprovalTypeListsApi().then(res => {
					this.approvalTypeLists = res.data;
				})
			},
			// 获取审批详情
			getApprovalDetails() {
				this.$api.getApprovalDetailsApi({id: this.$route.query.id}).then(res => {
					this.ruleForm.approvalType = res.data.approvalType;
					res.data.earlyApprovalId == 0 ? this.ruleForm.earlyApproval = '' : this.ruleForm.earlyApproval = res.data.earlyApprovalId;
					res.data.finalApprovalId == 0 ? this.ruleForm.finalApproval = '' : this.ruleForm.finalApproval = res.data.finalApprovalId;
					this.ruleForm.id = res.data.id;
				})
			}
		},
		created() {
			if (this.$route.name == 'editApproval') {
				this.acountMode = 'edit'
			}
			if (this.acountMode == 'edit') {
				this.getApprovalDetails();
			}
			this.getManagerLists();
			this.getApprovalTypeLists();
		},
		mounted() {},
		beforeDestroy() {},
	};
</script>
<style lang='scss' scoped>
	::v-deep .image-slot {
		font-size: 28px;
		color: #ccc;
	}

	.el-checkbox {
		height: 48px;
		line-height: 48px;
	}

	.info-avatar {
		width: 90px;
		height: 90px;
		margin-right: 25px;
		border-radius: 50%;
		overflow: hidden;
		background-color: #f5f7fa;
		border: 1px solid #f5f7fa;

		.avatar {
			width: 90px;
			height: 90px;
		}

		.tips {
			bottom: 0;
			height: 25px;
			left: 0;
			right: 0;
			line-height: 20px;
			text-align: center;
			background-color: rgba($color: #000000, $alpha: 0.6);
		}
	}

	::v-deep .el-form {
		.el-select {
			width: 100%;
		}

		.el-button,
		.el-form-item__label,
		.el-input__inner {
			height: 48px;
			line-height: 48px;
			font-size: 16px;
		}

		.el-button,
		.reset {
			width: 150px;
			margin: 10px auto 0;
			padding: 0;
		}

		.reset {
			margin-left: 30px;
			border: none;
			background-color: $-color-border;
		}
	}

	.el-select-dropdown__item {
		font-size: 16px;
		height: 44px;
		line-height: 44px;
	}
</style>
